<template>
	<chart
		title="Leadtime Report"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import Chart from "@common-components/chart/Chart.vue";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { ref, onMounted, watch } from "@vue/composition-api";
	import {
		getZonedDatesFromFullDates,
		getZonedYearMonthFromFullDate,
		getZonedYearFromFullDate,
		getLocalDateTimeString,
	} from "@/utils/date-time-helper";
	import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
	import { chartNumberFormatter } from "@/utils/ui-helper";
	import store from "@/store";
	import dayjs from "dayjs";
	import apis from "@configs/apis";

	export default {
		components: {
			Chart,
		},
		setup() {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const options = ref({});

			onMounted(() => {
				fetchChart();
			});

			watch(
				() => store.getters["kParcel/GET_QUERY_PARAMS"],
				function () {
					fetchChart();
				}
			);

			function setOptions(response, queryParams) {
				// let currentXAxis = Object.keys(response.currentBar);
				// let previousXAxis = Object.keys(response.previousAccumulateLine);

				// let xAxisData;
				// let currentLabel;
				// let previousLabel;

				// if (queryParams.xAxisMode == X_AXIS_MODE_DATE) {
				// 	currentLabel = getZonedYearMonthFromFullDate(
				// 		queryParams.currentStartDate
				// 	);
				// 	previousLabel = getZonedYearMonthFromFullDate(
				// 		queryParams.previousStartDate
				// 	);
				// 	xAxisData = getZonedDatesFromFullDates(
				// 		currentXAxis.length > previousXAxis.length
				// 			? currentXAxis
				// 			: previousXAxis
				// 	);
				// } else if (queryParams.xAxisMode == X_AXIS_MODE_MONTH) {
				// 	// X_AXIS_MODE_MONTH
				// 	currentLabel = getZonedYearFromFullDate(
				// 		queryParams.currentStartDate
				// 	);

				// 	previousLabel = getZonedYearFromFullDate(
				// 		queryParams.previousStartDate
				// 	);
				// 	xAxisData =
				// 		currentXAxis.length > previousXAxis.length
				// 			? currentXAxis
				// 			: previousXAxis;
				// } else {
				// 	currentLabel =
				// 		getZonedYearFromFullDate(queryParams.currentStartDate) +
				// 		"-" +
				// 		getZonedYearFromFullDate(queryParams.currentEndDate);
				// 	previousLabel =
				// 		getZonedYearFromFullDate(queryParams.previousStartDate) +
				// 		"-" +
				// 		getZonedYearFromFullDate(queryParams.previousEndDate);

				// 	xAxisData = Object.keys(response.currentBar);
				// }

				// let series = [
				// 	{
				// 		name: currentLabel,
				// 		yAxisIndex: 0,
				// 		data: Object.values(response.currentBar).map((val) =>
				// 			Math.round(val)
				// 		),
				// 		type: "bar",
				// 		zlevel: 2,
				// 	},
				// 	{
				// 		name: currentLabel + " (Accumulate)",
				// 		yAxisIndex: 1,
				// 		data: Object.values(response.currentAccumulateLine).map(
				// 			(val) => Math.round(val)
				// 		),
				// 		type: "line",
				// 		smooth: true,
				// 		symbol: "none",
				// 		areaStyle: {},
				// 		emphasis: {
				// 			focus: "series",
				// 		},
				// 		zlevel: 1,
				// 	},
				// 	{
				// 		name: previousLabel + " (Accumulate)",
				// 		yAxisIndex: 1,
				// 		data: Object.values(response.previousAccumulateLine).map(
				// 			(val) => Math.round(val)
				// 		),
				// 		type: "line",
				// 		smooth: true,
				// 		symbol: "none",
				// 		areaStyle: {},
				// 		emphasis: {
				// 			focus: "series",
				// 		},
				// 		zlevel: 0,
				// 		itemStyle: { color: "#dddddd" },
				// 	},
				// ];

				// let yAxis = [
				// 	{
				// 		type: "value",
				// 		axisLabel: {
				// 			formatter: customFormatter || chartNumberFormatter,
				// 		},
				// 		name: yAxisLabel,
				// 		nameLocation: "end",
				// 	},
				// 	{
				// 		type: "value",
				// 		axisLabel: {
				// 			formatter: customFormatter || chartNumberFormatter,
				// 		},
				// 		name: yAxisLabel2,
				// 		nameLocation: "end",
				// 	},
				// ];

				// let legend = {
				// 	// data: [currentLabel, currentAccLabel, previousLabel],
				// };

				// options.value = {
				// 	legend: customLegend != null ? customLegend(legend) : legend,
				// 	xAxis: {
				// 		type: "category",
				// 		data: xAxisData,
				// 	},
				// 	yAxis: customYAxis != null ? customYAxis(yAxis) : yAxis,
				// 	series: customSeries != null ? customSeries(series) : series,
				// };

				console.log("Jack", response);

				let xAxisData;
				if (queryParams.xAxisMode == X_AXIS_MODE_DATE) {
					xAxisData = getZonedDatesFromFullDates(
						Object.keys(response.data)
					);
				} else if (queryParams.xAxisMode == X_AXIS_MODE_MONTH) {
					// X_AXIS_MODE_MONTH
					xAxisData = Object.keys(response.data);
				} else {
					xAxisData = Object.keys(response.data);
				}

				let firstmileSLA = [];
				let linehaulSLA = [];
				let customsSLA = [];
				let lastmileSLA = []; //
				let completed = [];
				let firstmile = [];
				let linehaul = [];
				let customs = [];
				let lastmile = []; //

				Object.keys(response.data).forEach((key) => {
					let report = response.data[key];
					firstmileSLA.push(report.firstmileSla);
					linehaulSLA.push(report.linehaulSla);
					customsSLA.push(report.customsSla);
					lastmileSLA.push(report.lastmileSla);
					completed.push(report.completed);
					firstmile.push(report.firstmile);
					linehaul.push(report.linehaul);
					customs.push(report.customs);
					lastmile.push(report.lastmile);
				});

				let series = [
					{
						name: "Firstmile Leadtime",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(1)
							},
						},
						emphasis: {
							focus: "series",
						},
						data: firstmile,
						yAxisIndex: 0,
						itemStyle: { color: "#fc7a8f" },
					},
					{
						name: "Linehaul Leadtime",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(1)
							},
						},
						emphasis: {
							focus: "series",
						},
						data: linehaul,
						yAxisIndex: 0,
						itemStyle: { color: "#6c97be" },
					},
					{
						name: "Customs Leadtime",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(1)
							},
						},
						emphasis: {
							focus: "series",
						},
						data: customs,
						yAxisIndex: 0,
						itemStyle: { color: "#f0bd87" },
					},
				];

				if (response.isE2E) {
					series.push({
						name: "Lastmile Leadtime",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(1)
							},
						},
						emphasis: {
							focus: "series",
						},
						data: lastmile,
						yAxisIndex: 0,
						itemStyle: { color: "#93c47d" },
					});
				}

				series = [
					...series,
					{
						name: "Firstmile SLA",
						type: "line",
						data: firstmileSLA,
						yAxisIndex: 1,
						itemStyle: { color: "#fb1239" },
						lineStyle: {
							type: "dashed",
						},
					},
					{
						name: "Linehaul SLA",
						type: "line",
						data: linehaulSLA,
						yAxisIndex: 1,
						itemStyle: { color: "#0b5394" },
						lineStyle: {
							type: "dashed",
						},
					},
					{
						name: "Customs SLA",
						type: "line",
						data: customsSLA,
						yAxisIndex: 1,
						itemStyle: { color: "#e69138" },
						lineStyle: {
							type: "dashed",
						},
					},

					{
						name: "Completed %",
						type: "line",
						data: completed,
						yAxisIndex: 1,
						itemStyle: { color: "#c90076" },
					},
				];

				if (response.isE2E) {
					series.push({
						name: "Lastmile SLA",
						type: "line",
						data: lastmileSLA,
						yAxisIndex: 1,
						itemStyle: { color: "#38761d" },
						lineStyle: {
							type: "dashed",
						},
					});
				}

				options.value = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
						formatter: (params, ticket) => {
							let result = "";
							params.forEach((param) => {
								result +=
										"<div style='padding: 5px'>" +
										param.marker +
										param.seriesName +
										": " +
										param.value.toFixed(1) +
										"</div>";
							});

							return (
								result
							);
						},
					},
					yAxis: [
						{
							type: "value",
							axisLabel: {
								formatter: chartNumberFormatter,
							},
							name: "Leadtime (days)",
							nameLocation: "end",
						},
						{
							type: "value",
							axisLabel: {
								formatter: chartNumberFormatter,
							},
							name: "Completed %",
							nameLocation: "end",
						},
					],
					xAxis: {
						type: "category",
						data: xAxisData,
					},
					series,
				};
			}

			async function fetchChart() {
				isLoading.value = true;
				loadFail.value = false;

				let queryParamsObj = store.getters["kParcel/GET_QUERY_PARAMS"];

				try {
					const response = await fetcher(
						apis.kParcelLeadTimeReport,
						FetchMethod.GET,
						queryParamsObj
					);

					setOptions(response, queryParamsObj);

					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					console.log("Jack", e);
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			function onReload() {
				fetchChart();
			}

			return {
				isLoading,
				loadFail,
				lastUpdate,
				options,
				onReload,
			};
		},
	};
</script>

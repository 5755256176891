<template>
	<div class="h-100" id="root">
		<k-parcel-performance-filter />

		<!-- <data-card-list /> -->

		<div class="pb-2" style="flex: 1">
			<VueSlickCarousel v-bind="settings" class="remaining-area pt-1">
				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100">
						<div class="col-12 h-100 p-1">
							<LeadtimeReport />
						</div>
					</div>
				</div>

				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100">
						<div class="col-12 h-100 p-1">
							<DeliveryPerformance />
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import store from "@/store";
	import { computed, onBeforeMount, ref } from "@vue/composition-api";
	import KParcelPerformanceFilter from "./components/KParcelPerformanceFilter.vue";
	// import DataCardList from "./components/data-cards/operation/DataCardList.vue";
	import LeadtimeReport from "./components/charts/operations/LeadtimeReport.vue";
	import DeliveryPerformance from "./components/charts/operations/DeliveryPerformance.vue";
	import VueSlickCarousel from "vue-slick-carousel";

	import sizeHook from "@common-components/sizeHook";

	export default {
		components: {
			KParcelPerformanceFilter,
			// DataCardList,

			LeadtimeReport,
			DeliveryPerformance,

			VueSlickCarousel,
		},
		setup(props, ctx) {
			const [size, element] = sizeHook();

			onBeforeMount(() => {
				store.dispatch("kParcel/FETCH_FROM_COUNTRY_IF_NEED", { pickFirst: true });
				store.dispatch("kParcel/FETCH_TO_COUNTRY_IF_NEED", { pickFirst: true });
			});

			return {
				size,
				element,
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	#table-chart-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		height: 100%;
		width: 100%;
		margin-bottom: 20px;
	}
	.remaining-area {
		flex: 1;
	}
	.inner-chart {
		height: 350px !important;
	}
</style>
<template>
	<chart
		title="Delivery Performance"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import Chart from "@common-components/chart/Chart.vue";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { ref, onMounted, watch } from "@vue/composition-api";
	import {
		getZonedDatesFromFullDates,
		getZonedYearMonthFromFullDate,
		getZonedYearFromFullDate,
		getLocalDateTimeString,
	} from "@/utils/date-time-helper";
	import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
	import { chartNumberFormatter } from "@/utils/ui-helper";
	import store from "@/store";
	import dayjs from "dayjs";
	import apis from "@configs/apis";

	export default {
		components: {
			Chart,
		},
		setup() {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const options = ref({});

			onMounted(() => {
				fetchChart();
			});

			watch(
				() => store.getters["kParcel/GET_QUERY_PARAMS"],
				function () {
					fetchChart();
				}
			);

			function setOptions(response, queryParams) {
				let xAxisData;
				if (queryParams.xAxisMode == X_AXIS_MODE_DATE) {
					xAxisData = getZonedDatesFromFullDates(
						Object.keys(response.data)
					);
				} else if (queryParams.xAxisMode == X_AXIS_MODE_MONTH) {
					// X_AXIS_MODE_MONTH
					xAxisData = Object.keys(response.data);
				} else {
					xAxisData = Object.keys(response.data);
				}

				let d1To3 = [];
				let d4To6 = [];
				let d7To9 = [];
				let d9Plus = [];
				let missing = [];
				let total = [];

				Object.keys(response.data).forEach((key) => {
					let report = response.data[key];

					d1To3.push(report.d1To3);
					d4To6.push(report.d4To6);
					d7To9.push(report.d7To9);
					d9Plus.push(report.d9Plus);
					missing.push(report.missing);
					total.push(report.total);
				});

				let series = [
					{
						name: "D1 - D3",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(0) + "%";
							},
						},
						emphasis: {
							focus: "series",
						},
						data: d1To3,
						yAxisIndex: 0,
						itemStyle: { color: "#93c47d" },
					},
					{
						name: "D4 - D6",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(0) + "%";
							},
						},
						emphasis: {
							focus: "series",
						},
						data: d4To6,
						yAxisIndex: 0,
						itemStyle: { color: "#6fa8dc" },
					},
					{
						name: "D7 - D9",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(0) + "%";
							},
						},
						emphasis: {
							focus: "series",
						},
						data: d7To9,
						yAxisIndex: 0,
						itemStyle: { color: "#f6b26b" },
					},
					{
						name: "D9+",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(0) + "%";
							},
						},
						emphasis: {
							focus: "series",
						},
						data: d9Plus,
						yAxisIndex: 0,
						itemStyle: { color: "#e24848" },
					},
					{
						name: "End Status not Received",
						type: "bar",
						stack: "total",
						label: {
							show: true,
							formatter: function (d) {
								return d.value.toFixed(0) + "%";
							},
						},
						emphasis: {
							focus: "series",
						},
						data: missing,
						yAxisIndex: 0,
						itemStyle: { color: "#dddddd" },
					},
					{
						name: "Volume by Week",
						type: "line",
						data: total,
						yAxisIndex: 1,
						itemStyle: { color: "#444444" },
					},
				];

				options.value = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
						formatter: (params, ticket) => {
							let result = "";
							params.forEach((param) => {
								result +=
										"<div style='padding: 5px'>" +
										param.marker +
										param.seriesName +
										": " +
										param.value.toFixed(0) +
										(param.seriesType == 'bar' ? "%" : "") +
										"</div>";
							});

							return (
								result
							);
						},
					},
					yAxis: [
						{
							type: "value",
							axisLabel: {
								formatter: chartNumberFormatter,
							},
							name: "Distribution %",
							nameLocation: "end",
							max: 100
						},
						{
							type: "value",
							axisLabel: {
								formatter: chartNumberFormatter,
							},
							name: "Parcel Count",
							nameLocation: "end",
						},
					],
					xAxis: {
						type: "category",
						data: xAxisData,
					},
					series,
				};
			}

			async function fetchChart() {
				isLoading.value = true;
				loadFail.value = false;

				let queryParamsObj = store.getters["kParcel/GET_QUERY_PARAMS"];

				try {
					const response = await fetcher(
						apis.kParcelDeliveryPerformance,
						FetchMethod.GET,
						queryParamsObj
					);

					setOptions(response, queryParamsObj);

					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					console.log("Jack", e);
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			function onReload() {
				fetchChart();
			}

			return {
				isLoading,
				loadFail,
				lastUpdate,
				options,
				onReload,
			};
		},
	};
</script>
